// The current application coordinates were pre-registered in a B2C tenant.

const baseUrl = process.env.REACT_APP_FUNCTION_BASE_URL || "http://localhost:7071"

console.log(process.env)

export const apiConfig = {
  userApi: `${baseUrl}/api/user`,
  groupObjectApi: `${baseUrl}/api/group/group`,
  groupUserPageApi: `${baseUrl}/api/group/userpage`,
  groupMemberSettingsApi: `${baseUrl}/api/group/membersettings`,
  groupGroupSettingsApi: `${baseUrl}/api/group/groupsettings`,
  groupMemberGenerateOTPApi: `${baseUrl}/api/member/generateotp`,
  groupMemberretrievememberApi: `${baseUrl}/api/member/retrievemember`,
  groupMemberaddeditdeletememberApi: `${baseUrl}/api/member/addeditdeletemember`,
  groupUserAddsMemberApi: `${baseUrl}/api/member/useraddmember`,
  groupMemberMembersApi: `${baseUrl}/api/member/members`,
  groupMemberStatsApi: `${baseUrl}/api/member/stats`,
  groupMemberPageInfoApi: `${baseUrl}/api/member/pageinfo`,
  groupStatusApi: `${baseUrl}/api/group/status`,
  groupActivateApi: `${baseUrl}/api/group/activate`,
  groupFirstActivateApi: `${baseUrl}/api/group/firstactivate`,
  groupDisableApi: `${baseUrl}/api/group/disable`,
  billingPlansApi: `${baseUrl}/api/billing/plans`,
  billingPlanSelectedApi: `${baseUrl}/api/billing/planselected`,
  loginApi: `${baseUrl}/api/login`,
  computeMatchApi: `${baseUrl}/api/match/compute`,
  paymentApi: `${baseUrl}/api/payment`,
  stripeApi: `${baseUrl}/api/stripe`
  }