// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const loggedIn = 1
export const loggedOut = 0

export const userSlice = createSlice({
  name: 'userreducer',
  initialState: {
    status: loggedOut,
    redirectStart: false
  },
  reducers: {
    handleLogin: state => {
      state.status = loggedIn
    },
    handleLogout: state => {
      state.status = loggedOut
      state.redirectStart = false
    },
    redirectStarted: state => {
      state.redirectStart = true
    }
  },
  extraReducers: {

  }
  }
)

export const { handleLogin, handleLogout, redirectStarted } = userSlice.actions

export default userSlice.reducer
